:root {
  --main-theme-color: #ff9925;
  --heading-color: #032c56;
  --paragraph-color: #5f6a75;
  --green: rgb(14, 124, 54);
  --orange: #ff9925;
}

/*text color*/
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
